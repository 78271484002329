<template>
  <UserSearchResultCard
    to="mentor-profile"
    :data="mentor"
    :skills="mentor.skills"
    price-title="Цена за занятие"
    :price="mentor.price"
  >
    <template #action><BaseButton v-if="!user?.is_mentor" class="select-btn" color-primary :to="{ name: 'mentor-book', params: { id: mentor.id } }" @click.stop>Выбрать ментора</BaseButton></template>
  </UserSearchResultCard>
</template>

<script setup>
const props = defineProps({
  mentor: Object
})

const user = inject('user')
</script>

<style scoped lang="scss">

</style>
