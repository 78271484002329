<template>
  <SearchLayout
    class="mentors-list"
    :class="{ pending }"
    :filters="filters"
    :pagination="pagination"
    no-items-text="Мы не нашли подходящих менторов"
  >
    <template v-if="skill || !user?.is_mentor" #before>
      <BaseBanner :title-tag="skill ? 'h1' : 'h2'">
        <template v-if="skill" #title>Менторы
          <BaseBannerAvatars/>
          <br>по&nbsp;{{ skill.name }}
        </template>
        <template v-else #title>Подбери подходящего ментора
          <BaseBannerAvatars/>
          и&nbsp;прокачайся
        </template>
        <template #steps>
          <BaseBannerStep number="1">
            <template #icon><img src="~/assets/img/icons/3d-person.png" alt="Ментор"></template>
            Выбирай ментора<br>в каталоге
          </BaseBannerStep>
          <BaseBannerStep number="2">
            <template #icon><img src="~/assets/img/icons/3d-calendar.png" alt="Календарь"></template>
            Бронируй занятие<br>через календарь
          </BaseBannerStep>
          <BaseBannerStep number="3">
            <template #icon><img src="~/assets/img/icons/3d-bell.png" alt="Ментор"></template>
            Мы уведомим тебя<br>перед началом занятия
          </BaseBannerStep>
        </template>
      </BaseBanner>
    </template>

    <template #title>
      <PageTitle :tag="skill ? 'h2' : 'h1'" :note="pagination.total">Менторы</PageTitle>
    </template>
    <template #controls="{ isMobile }">
      <SortControl :chip="isMobile" v-if="sortOptions" :options="sortOptions" v-model="filter.sort"/>
    </template>
    <template #price="{ active }">
      <FilterPrice
        ref="slider"
        v-model:from="filter.rate_from"
        v-model:to="filter.rate_to"
        :min="meta.min_rate"
        :max="meta.max_rate"
        @change="onChange"
        :active="active.value"
      />
    </template>
    <template #skills="{ single, active }">
      <FilterSkills
        :items="meta.skills"
        :fetched="fetchedSkills"
        v-model="filter.skills"
        :single="single"
        :active="active.value"
      />
    </template>

    <template #default>
      <MentorCard
        v-for="mentor in mentors"
        :key="mentor.id"
        :mentor="mentor"
      />
    </template>

    <template #seo-block>
      <SeoBlock v-if="seo.length" :seo="seo"/>
    </template>

    <template #after>
      <FaqMentor :faq="faqs"/>
      <BecomeMentor class="become-section"/>
    </template>
  </SearchLayout>
</template>

<script setup>
import {getMentorsMeta, searchMentors} from '@/api/mentoring'
import MentorCard from '@/components/mentor/MentorSearchResultCard'
import BecomeMentor from '@/components/parts/BecomeMentor'
import usePagination from '~/composables/usePagination'
import {FilterBuilder} from '~/utils/filters'
import {getSkill} from '~/api/skills'
import useIsMounted from '~/composables/useIsMounted'
import FaqMentor from "~/components/parts/FaqMentor.vue";
import {getFaq} from "~/api/faq";

const router = useRouter()
const route = useRoute()

const user = inject('user')

const faqPlace = 'mentors'

const faqs = await getFaq(faqPlace);
const seo = await getFaq(faqPlace, 1)

const {data: skill} = await useAsyncData(async () => route.params?.slug ? getSkill(route.params.slug) : null)

useHead({
  title: skill.value?.title
    ?? (skill.value?.name
      ? `Менторы по ${skill.value.name} – ${skill.value.name}-разработчик с нуля – обучение ${skill.value.name} с наставником`
      : 'Поиск ментора по IT-направлениям - поиск наставника в сфере IT')
})

if (skill.value) {
  useServerSeoMeta({
    description: skill.value.description,
    keywords: skill.value.keywords
  })
} else {
  useServerSeoMeta({
    description: 'Подбор и поиск менторов онлайн по IT-направлениям. Найти IT-наставника из своей сферы деятельности на платформе менторинга',
  })
}
const sortOptions = [
  {
    id: 'top',
    name: 'Популярные'
  },
  {
    id: 'new_first',
    name: 'Новые'
  },
  {
    id: 'price_asc',
    name: 'Цена ниже'
  },
  {
    id: 'price_desc',
    name: 'Цена выше'
  }
]

const slider = ref(null)

const filter = ref({
  rate_from: '',
  rate_to: '',
  skills: skill.value ? [skill.value.id] : [],
  sort: sortOptions[0].id
})

const {data: meta} = await useAsyncData(getMentorsMeta)
filter.value.rate_from = meta.value.min_rate
filter.value.rate_to = meta.value.max_rate

;(query => {
  if (!isNaN(+query.rate_from)) filter.value.rate_from = +query.rate_from
  if (!isNaN(+query.rate_to)) filter.value.rate_to = +query.rate_to
  if (query['skills[]']) filter.value.skills = [].concat(query['skills[]']).map(s => +s).filter(s => !isNaN(s))
  if (query.sort) filter.value.sort = query.sort
})(route.query)

const onChange = (name) => {
  filter.value[name] = Math.max(
    name === 'rate_from' ? meta.value.min_rate : filter.value.rate_from, // min
    Math.min(
      name === 'rate_from' ? filter.value.rate_to : meta.value.max_rate, // max
      filter.value[name] // value
    )
  )

  nextTick(() => slider.value?.updateValue())
}

const filters = new FilterBuilder()
  .price(filter, meta, onChange)
  .skills(filter, () => meta.value.skills, skill.value?.id)
  .build()

const fetchedSkills = ref(filter.value.skills.slice())
const isMounted = useIsMounted()

const {
  loading: pending,
  pagination,
  page,
  items: mentors,
  load
} = await usePagination(
  (page, perPage) => {
    const data = {
      page,
      rate_from: filter.value.rate_from,
      rate_to: filter.value.rate_to,
      'skills[]': filter.value.skills,
      sort: filter.value.sort
    }
    if (isMounted.value
      || route.query.page
      || filters.some(f => !f.isClear?.value)
      || data.sort !== sortOptions[0].id)
      if (isMounted.value) router.replace({query: data})

    fetchedSkills.value = filter.value.skills.slice()
    return searchMentors({
      ...data,
      per_page: perPage
    })
  },
  ref(5),
  {key: 'mentors'}
)

watchDebounced(filter, () => {
  if (pagination.value.current_page === 1) load()
  else page.value = 1
}, {deep: true, debounce: 800})
</script>

<style scoped lang="scss">

</style>
